/* eslint-disable @next/next/no-img-element */
import Image from "next/image";
import React from "react";
import { withTranslation } from "react-i18next";
// import { DollarOutlined, FieldTimeOutlined } from "@ant-design/icons";
import WatchADemoButton from "./watchADemoButton";
import styles from "@buddieshr/styles/billy/readyForBilly.module.scss";
import AddToSlackButton from "../addToSlackButton";

const ReadyForBilly = ({ t, app }) => (
  <div className={styles.Ready_wrapper}>
    <div className={styles.Ready_inner}>
      <div className={styles.Ready_content}>
        <h2 className={styles.Ready_title}>{t("readyToWelcomeBilly")}</h2>
        <div className={styles.ReadyBilly_logos}>
          <span style={{ marginRight: "6px" }}>{"Available on "}</span>

          <Image src="/img/slack_80.png" alt="slack" width={20} height={20} />
          <span
            className={styles.bold}
            style={{ marginLeft: "8px", marginRight: "6px" }}
          >
            {"Slack "}
          </span>
        </div>
        <div>
          <div className={styles.Action_wrapper}>
            <AddToSlackButton
              style={{ width: "auto", minWidth: "220px" }}
              app={app}
              id="CTA_bottom"
            />
            <WatchADemoButton app={app} />
          </div>
        </div>
      </div>

      <div>
        <Image
          src="/img/apps/billy/logo_without_background.png"
          className={styles.Ready_logo}
          alt="logo celebate bot"
          title="logo celebate bot"
          width={160}
          height={160}
        />
      </div>
    </div>
  </div>
);

export default withTranslation()(ReadyForBilly);
