import { Avatar, Rate } from "antd";
import React from "react";
import styles from "@buddieshr/styles/billy/reviews.module.scss";
import Image from "next/image";
import { useTranslation } from "react-i18next";
import { UserOutlined } from "@ant-design/icons";

const Testimonial = ({ testimonial: testi = {}, style = {} }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.Trusted_by_quote} key={testi.from} style={style}>
      <div style={{ textAlign: "left", marginBottom: "4px" }}>
        <Rate value={5} disabled />
      </div>
      <div className={styles.Trusted_by_quote_inner}>
        <div className={styles.quote}>{t(testi.quote)}</div>
        <div className={styles.Trusted_by_image}>
          {testi.profilePic ? (
            <Image
              src={testi.profilePic}
              alt={t(testi.from)}
              width={50}
              height={50}
              className={styles.Trusted_by_image_element}
            />
          ) : (
            <Avatar size={50} icon={<UserOutlined />} />
          )}
        </div>
        <div className={styles.Trusted_by_customer}>{t(testi.from)}</div>
        <div itemType="https://schema.org/Product" itemScope>
          <meta itemProp="name" content="Billy Birthday" />
          <meta itemProp="description" content={t(testi.quote)} />
          <div itemProp="review" itemType="https://schema.org/Review" itemScope>
            <div
              itemProp="author"
              itemType="https://schema.org/Person"
              itemScope
            >
              <meta itemProp="name" content={t(testi.from)} />
            </div>
            <div
              itemProp="reviewRating"
              itemType="https://schema.org/Rating"
              itemScope
            >
              <meta itemProp="ratingValue" content="5" />
              <meta itemProp="bestRating" content="5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
