import React from "react";
import { useTranslation } from "react-i18next";
import styles from "@buddieshr/styles/billy/featureTags.module.scss";

const FeatureTags = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.FeaturesTag_wrapper}>
      <div className={styles.FeaturesTag_inner}>
        <div className={styles.FeaturesTag_title}>{t("smartFeatures")}</div>
        <div className={styles.FeaturesTag_element}>
          <div className={styles.FeaturesTag_icon}>🎉</div>
          <div className={styles.FeaturesTag_name}>{t("funCopywriting")}</div>
        </div>
        <div className={styles.FeaturesTag_element}>
          <div className={styles.FeaturesTag_icon}>📆</div>
          <div className={styles.FeaturesTag_name}>{t("weekendFeature")}</div>
        </div>
        <div className={styles.FeaturesTag_element}>
          <div className={styles.FeaturesTag_icon}>❎</div>
          <div className={styles.FeaturesTag_name}>{t("optoutOption")}</div>
        </div>
        <div className={styles.FeaturesTag_link}>
          <a href="#features">{t("seeMore")}</a>
        </div>
      </div>
    </div>
  );
};

export default FeatureTags;
