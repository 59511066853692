import Image from "next/image";
import React from "react";
import { withTranslation } from "react-i18next";
import styles from "@buddieshr/styles/billy/howItWorks.module.scss";

const HowItWorks = ({ t }) => (
  <div className={styles.How_it_works_wrapper} id="how">
    <h2 className={styles.title}>{t("howItWorks")}</h2>
    <div className={styles.subtitle}>{t("followTheseSteps")}</div>
    <div className={styles.How_it_works_list}>
      <div className={styles.How_it_works_element}>
        <div className={styles.How_it_works_element_inner}>
          <div className={styles.How_it_works_element_pic}>
            <Image
              className={styles.How_it_works_element_pic_element}
              src="/img/apps/billy/howto/install.png"
              alt="connect Slack"
              title="connect Slack"
              width={560}
              height={320}
            />
          </div>
          <div className={styles.How_it_works_element_inner_right}>
            <div className={styles.How_it_works_number}>1</div>
            <h3 className={styles.How_it_works_element_title}>
              {t("howTo1_title")}
            </h3>
            <div className={styles.How_it_works_element_desc}>
              {t("howTo1_desc")}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.How_it_works_element}>
        <div className={styles.How_it_works_element_inner}>
          <div className={styles.How_it_works_element_pic}>
            <Image
              className={styles.How_it_works_element_pic_element}
              src="/img/apps/billy/howto/channel.png"
              alt="choose channel Slack or teams"
              width={560}
              height={320}
            />
          </div>
          <div className={styles.How_it_works_element_inner_right}>
            <div className={styles.How_it_works_number}>2</div>

            <h3 className={styles.How_it_works_element_title}>
              {t("howTo11_title")}
            </h3>
            <div className={styles.How_it_works_element_desc}>
              {t("howTo11_desc")}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.How_it_works_element}>
        <div className={styles.How_it_works_element_inner}>
          <div
            className={styles.How_it_works_element_pic}
            // style={{ background: "#90d3ff" }}
          >
            <Image
              className={styles.How_it_works_element_pic_element}
              src="/img/apps/billy/howto/collect.png"
              alt="add birthday dates"
              title="add birthday dates"
              width={560}
              height={320}
            />
          </div>
          <div className={styles.How_it_works_element_inner_right}>
            <div className={styles.How_it_works_number}>3</div>

            <h3 className={styles.How_it_works_element_title}>
              {t("howTo2_title")}
            </h3>
            <div className={styles.How_it_works_element_desc}>
              {t("howTo2_desc")}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.How_it_works_element}>
        <div className={styles.How_it_works_element_inner}>
          <div
            className={styles.How_it_works_element_pic}
            // style={{ background: "#5ef492" }}
          >
            <Image
              className={styles.How_it_works_element_pic_element}
              src="/img/square_visual.png"
              alt="setup finished"
              title="setup finished"
              width={400}
              height={400}
            />
          </div>
          <div className={styles.How_it_works_element_inner_right}>
            <div className={styles.How_it_works_number}>4</div>
            <h3 className={styles.How_it_works_element_title}>
              {t("howTo3_title")}
            </h3>
            <div className={styles.How_it_works_element_desc}>
              {t("howTo3_desc")}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default withTranslation()(HowItWorks);
