import { Button, Carousel, Rate } from "antd";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { TESTIMONIALS_BILLY } from "@buddieshr/utils/constants";
import styles from "@buddieshr/styles/billy/reviews.module.scss";
import { useWindowSize } from "@buddieshr/utils/utils";
import Testimonial from "./testimonial";
import { ArrowRightOutlined } from "@ant-design/icons";
import Link from "next/link";
import Rated from "../rated";

const Reviews = ({ t, withoutBackground = false, withRate = false }) => {
  const size = useWindowSize();
  const mobile = size.width < 850;
  let car = React.createRef();
  const [current, setCurrent] = useState(0);

  const onChange = (currentSlide) => {
    setCurrent(currentSlide);
  };

  return (
    <div
      className={`${styles.Trusted_by_wrapper} ${
        withoutBackground ? styles.withoutBackground : ""
      }`}
      id="reviews"
    >
      <div className={styles.Trusted_by_inner}>
        <h2 className={styles.Trusted_by_title}>{t("trustedBy")}</h2>
        {withRate && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "28px",
            }}
          >
            <Rated
              fullWidth
              style={{ marginTop: "44px", marginBottom: "20px" }}
            />
          </div>
        )}
        <div
          className={`${styles.Trusted_by_quote_wrapper} ${
            mobile ? styles.mob : ""
          }`}
        >
          {mobile ? (
            <>
              <Carousel
                afterChange={onChange}
                ref={(node) => (car = node)}
                dots={false}
                className={styles.Trusted_mobile_slider}
                style={{ width: "100%" }}
              >
                {TESTIMONIALS_BILLY.map((testi) => (
                  <Testimonial testimonial={testi} key={testi.from} />
                ))}
              </Carousel>
              <div className={styles.Trusted_mobile_infos_wrapper}>
                <div className={styles.Trusted_mobile_info_number}>{`${
                  current + 1
                }/${TESTIMONIALS_BILLY.length}`}</div>
                <Button
                  onClick={() => {
                    car.prev();
                  }}
                  type="link"
                  size="large"
                  className={styles.Trusted_mobile_info_prev}
                >
                  {`< ${t("previous")}`}
                </Button>
                <Button
                  onClick={() => {
                    car.next();
                  }}
                  type="link"
                  size="large"
                  className={styles.Trusted_mobile_info_next}
                >
                  {`${t("next")} >`}
                </Button>
              </div>
            </>
          ) : (
            <>
              {TESTIMONIALS_BILLY.map((testi) => (
                <Testimonial testimonial={testi} key={testi.from} />
              ))}
            </>
          )}
        </div>
        <div>
          <Link href="/billy/reviews">
            <Button
              className={styles.cta_readmore}
              type="link"
              icon={<ArrowRightOutlined />}
              size="large"
            >
              {t("readMoreReviews")}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Reviews);
